import axios from "@/api/axios";
import _ from "lodash";
import qs from "qs";

// 피팅 리스트
const get_fittinglist = async (param, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittinglist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      pagesize: param.pagesize,
      findex: param.findex,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("set_memberswingopen", err);
    result(false, err);
  }
};

// 피팅 스윙 리스트
const get_fittingswinglist = async (fittingno, param, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittingswinglist/" + fittingno,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittingswinglist", err);
    result(false, err);
  }
};

// 피팅 스윙 리스트
const get_fittingswingview = async (fittingno, fit_binary_pk, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/fitting/fittingswingview/" +
      fittingno +
      "/" +
      fit_binary_pk,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittingswingview", err);
    result(false, err);
  }
};

const get_fittinganswerview = async (fittingreqno, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/fitting/fittinganswerview/" +
      fittingreqno,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinganswerview", err);
    result(false, err);
  }
};

// 판매보기
const get_saleview = async (fit_ans_pk, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/saleview/" + fit_ans_pk,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_saleview", err);
    result(false, err);
  }
};

// 피팅내역보기
const get_fitresultview = async (fittingreqno, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fitresultview/" + fittingreqno,
    headers: { Authorization: "Bearer " + token },
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fitresultview", err);
    result(false, err);
  }
};

const get_fittingreqinfo = async (result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittingreqinfo/",
    headers: { Authorization: "Bearer " + token },
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fitresultview", err);
    result(false, err);
  }
};

const post_fittingrequestwrite = async (form, fittingno, result) => {
  const token = sessionStorage.getItem("token") || null;
  const data = new FormData();

  for (let key in form) {
    if (typeof form[key] === "object") {
      if (Array.isArray(form[key])) {
        form[key].forEach((v) => data.append(key + "[]", v));
      } else {
        data.append(key, JSON.stringify(form[key]));
      }
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/fitting/fittingrequestwrite/" + fittingno,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },

    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_fittingDrop = async (form, result) => {
  const token = sessionStorage.getItem("token") || null;
  const data = new FormData();

  for (let val of form) {
    data.append("fit_pk[]", val);
  }

  const options = {
    method: "POST",
    url: "/postact/fittingdrop/",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },

    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_fittingGroup = async (form, result) => {
  const token = sessionStorage.getItem("token") || null;
  const data = new FormData();

  for (let val of form) {
    data.append("fit_pk[]", val);
  }

  const options = {
    method: "POST",
    url: "/postact/fittinggroup/",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },

    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const put_groupDrop = async (fit_group_no, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "PUT",
    url: "/postact/groupdrop/" + fit_group_no,
    headers: {
      Authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const put_fittingBuy = async (fit_ans_pk, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "PUT",
    url: "/postact/fittingbuy/" + fit_ans_pk,
    headers: {
      Authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const put_buyCancel = async (fit_req_pk, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "PUT",
    url: "/postact/buycancel/" + fit_req_pk,
    headers: {
      Authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_fittingCancel = async (fit_req_pk, result) => {
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "DELETE",
    url: "/postact/fittingcancel/" + fit_req_pk,
    headers: {
      Authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_fittinglist,
  get_fittingswinglist,
  get_fittingswingview,
  get_fittinganswerview,
  get_saleview,
  get_fitresultview,
  get_fittingreqinfo,
  post_fittingrequestwrite,
  post_fittingDrop,
  post_fittingGroup,
  put_groupDrop,
  put_fittingBuy,
  put_buyCancel,
  delete_fittingCancel,
};
